var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "head" },
    [
      _c(
        "div",
        { staticClass: "topTitle paddingL20 paddingT10" },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [_c("titleModule", { attrs: { title: "制作节目" } })],
            1
          ),
          _c("el-col", { attrs: { span: 12 } }, [
            _c(
              "div",
              { staticClass: "handleBtn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "medium" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v("返回")]
                ),
                !this.$route.query.isDetail
                  ? _c(
                      "el-button",
                      {
                        attrs: { size: "medium", type: "success" },
                        on: { click: _vm.save },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.preservation")))]
                    )
                  : _vm._e(),
                _c(
                  "el-button",
                  { attrs: { size: "medium" }, on: { click: _vm.getPreview } },
                  [_vm._v("预览")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "Wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticStyle: { width: "65%" },
              attrs: {
                "label-position": "right",
                "label-width": "140px",
                model: _vm.formInline,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "节目名称:", prop: "programName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      disabled: _vm.isEdit,
                      placeholder: "请输入素材名称",
                      maxlength: "30",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.formInline.programName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "programName", $$v)
                      },
                      expression: "formInline.programName",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "请选择素材:" } }, [
                _vm._v(" （系统根据素材添加顺序播放） "),
              ]),
              _c(
                "div",
                { staticClass: "tableWrapper" },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "800px" },
                      attrs: { data: _vm.tableData },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "materialName",
                          label: _vm.$t("searchModule.Material_Name"),
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "playTime",
                          label: "播放时长",
                          align: "center",
                        },
                      }),
                      !this.$route.query.isDetail
                        ? _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "操作",
                              width: "400",
                              type: "index",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.$index != _vm.tableData.length - 1
                                        ? _c("el-button", {
                                            staticClass: "el-icon-bottom",
                                            on: {
                                              click: function ($event) {
                                                return _vm.downGo(
                                                  _vm.tableData,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      scope.$index != 0
                                        ? _c("el-button", {
                                            staticClass: "el-icon-top",
                                            on: {
                                              click: function ($event) {
                                                return _vm.upGo(
                                                  _vm.tableData,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDelete(
                                                scope.$index,
                                                scope.row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2700936634
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              !this.$route.query.isDetail
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addDomain },
                        },
                        [_vm._v("添加素材")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialogVisible",
          attrs: {
            "close-on-click-modal": false,
            title: "请选择节目单素材",
            visible: _vm.dialogVisible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              attrs: { data: _vm.tableMatter },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "materialName",
                  label: _vm.$t("searchModule.Material_Name"),
                  width: "600",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.previewVisible
        ? _c(
            "div",
            {
              staticClass: "previewBox",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.previewVisible = false
                },
              },
            },
            [
              _c("div", [
                _c(
                  "div",
                  {
                    staticClass: "preview",
                    style: {
                      width: _vm.showImg.screenWidth + "px",
                      height: _vm.showImg.screenHeight + "px",
                    },
                  },
                  [
                    _vm.materialType == 1 ||
                    _vm.materialType == 2 ||
                    _vm.materialType == 10
                      ? _c(
                          "div",
                          {
                            staticClass: "imgPreview",
                            style: {
                              left: _vm.showText.mediaX + "px",
                              top: _vm.showText.mediaY + "px",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: _vm.showImg.mediaResourceUrl,
                                width: _vm.showImg.mediaShowWidth,
                                height: _vm.showImg.mediaShowHeight,
                                alt: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.materialType == 4 || _vm.materialType == 12
                      ? _c(
                          "div",
                          {
                            staticClass: "imgPreview",
                            style: {
                              left: _vm.showText.mediaX + "px",
                              top: _vm.showText.mediaY + "px",
                            },
                          },
                          [
                            _c("video", {
                              attrs: {
                                src: _vm.showImg.mediaResourceUrl,
                                width: _vm.showImg.mediaShowWidth,
                                height: _vm.showImg.mediaShowHeight,
                                controls: "",
                                autoplay: "",
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "text",
                        style: {
                          left: _vm.showText.textX + "px",
                          top: _vm.showText.textY + "px",
                          width: _vm.showText.textShowWidth + "px",
                          height: _vm.showText.textShowHeight + "px",
                          fontFamily: _vm.textFont,
                          fontSize: _vm.showText.textFontSize + "px",
                          color: _vm.styleFontColor,
                          textAlign: _vm.styleTextAlign,
                          fontWeight:
                            _vm.showText.textFontStyle === 1 ? 600 : 400,
                          fontStyle:
                            _vm.showText.textFontStyle === 2
                              ? "italic"
                              : "normal",
                          textDecoration:
                            _vm.showText.textFontStyle === 4
                              ? "underline"
                              : "normal",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.showText.textContent) + " ")]
                    ),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }